export default {
    root: ({props}) => ({
        class: [
            // Flex and Position
            'flex relative',
            {'justify-center': props.layout == 'vertical'},
            {'items-center': props.layout == 'vertical'},
            {
                'justify-start':
                    (props == null ? void 0 : props.align) == 'left' &&
                    props.layout == 'horizontal',
                'justify-center':
                    (props == null ? void 0 : props.align) == 'center' &&
                    props.layout == 'horizontal',
                'justify-end':
                    (props == null ? void 0 : props.align) == 'right' &&
                    props.layout == 'horizontal',
                'items-center':
                    (props == null ? void 0 : props.align) == 'top' && props.layout == 'vertical',
                'items-start':
                    (props == null ? void 0 : props.align) == 'center' &&
                    props.layout == 'vertical',
                'items-end':
                    (props == null ? void 0 : props.align) == 'bottom' &&
                    props.layout == 'vertical',
            },
            // Spacing
            {
                'px-5': props.layout == 'horizontal',
                'py-5': props.layout == 'vertical',
            },
            // Size
            {
                'w-full': props.layout == 'horizontal',
                'min-h-full': props.layout == 'vertical',
            },
            // Before: Line
            'before:block',
            // Position
            {
                'before:absolute before:left-0 before:top-1/2': props.layout == 'horizontal',
                'before:absolute before:left-1/2 before:top-0 before:transform before:-translate-x-1/2':
                    props.layout == 'vertical',
            },
            // Size
            {
                'before:w-full': props.layout == 'horizontal',
                'before:min-h-full': props.layout == 'vertical',
            },
            // Shape
            {
                'before:border-solid': props.type == 'solid',
                'before:border-dotted': props.type == 'dotted',
                'before:border-dashed': props.type == 'dashed',
            },
            // Color
            {
                'before:border-t before:border-zinc-200': props.layout == 'horizontal',
                'before:border-l before:border-zinc-200': props.layout == 'vertical',
            },
        ],
    }),
    content: ({props}) => ({
        class: [
            'z-10',
            {
                'bg-background-light':
                    (props == null ? void 0 : props.align) == 'center' &&
                    props.layout == 'horizontal',
            },
        ],
    }),
};

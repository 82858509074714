type RGB = {
    r: number;
    g: number;
    b: number;
};

export const primaryVariants = {
    'highlight-active': 0.1,
    'highlight-hover': 0.05,
    'd-hover': 0.8,
    'd-active': 0.9,
};

export const secondaryVariants = {
    idle: 0.5,
    hover: 0.2,
    active: 0.4,
};

export function mixColors(color1: RGB, color2: RGB, percentage: number): RGB {
    const r = Math.round(color1.r * percentage + color2.r * (1 - percentage));
    const g = Math.round(color1.g * percentage + color2.g * (1 - percentage));
    const b = Math.round(color1.b * percentage + color2.b * (1 - percentage));
    return {r, g, b};
}

export function hexToRgb(hex: string): RGB {
    const bigint = parseInt(hex.slice(1), 16);
    return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
    };
}

export function rgbToHex({r, g, b}: RGB): string {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}

export function setColorVariants(
    color: string,
    colorPrefix: string,
    variants: Record<string, number>
) {
    const colorRgb = hexToRgb(color);
    const whiteRgb = {r: 255, g: 255, b: 255};

    for (const variant in variants) {
        const value = variants[variant];
        document.documentElement.style.setProperty(
            `--${colorPrefix}-${variant}`,
            rgbToHex(mixColors(colorRgb, whiteRgb, value))
        );
    }
}

export default {
    root: ({props, parent}) => ({
        class: [
            // Font
            {
                'text-xl': props.size == 'large',
                'text-2xl': props.size == 'xlarge',
            },

            // Alignments
            'inline-flex items-center justify-center',
            'relative',

            // Sizes
            {
                'h-6 w-6': props.size == null || props.size == 'normal',
                'w-[40px] h-[40px]': props.size == 'large',
                'w-16 h-16': props.size == 'xlarge',
            },
            {'-ml-6': parent.instance.$style?.name == 'avatargroup'},

            // Shapes
            {
                'rounded-lg': props.shape == 'square',
                'rounded-full': props.shape == 'circle',
            },
            {'border-2': parent.instance.$style?.name == 'avatargroup'},

            // Colors
            'bg-zinc-400 dark:bg-zinc-700',
            'border-2 border-zinc-500',
        ],
    }),
    image: ({props}) => ({
        class: [
            'h-full w-full',
            {
                'rounded-lg': props.shape == 'square',
                'rounded-full': props.shape == 'circle',
            },
        ],
    }),
};

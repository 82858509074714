// Config object to be passed to Msal on creation
import router from '../../router/router';

/**
 * @returns {{scope: string, response_type: string, redirect_uri: string, client_id: any}}
 */
export const googleConfig = () => ({
    client_id: import.meta.env.VITE_OAUTH_GOOGLE_ID,
    redirect_uri:
        window.location.origin.replace(new RegExp(/(https:\/\/)([\w-]+)(\..+)/i), '$1app$3') +
        router.resolve({name: 'GoogleCallback'}).href,
    response_type: 'code',
    scope: 'email profile openid',
    access_type: 'offline',
    prompt: 'consent',
});

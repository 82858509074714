/**
 * Clamps a number within the inclusive range defined by `min` and `max`.
 *
 * @param {number} number - The number to clamp.
 * @param {number} min - The lower bound of the range.
 * @param {number} max - The upper bound of the range.
 * @returns {number} - The clamped value, ensuring it stays between `min` and `max`.
 *
 * @example
 * clamp(5, 1, 10); // Returns 5
 * clamp(-2, 0, 10); // Returns 0
 * clamp(15, 1, 10); // Returns 10
 */
export function clamp(number: number, min: number, max: number): number {
    return Math.max(min, Math.min(number, max));
}

export function roundFloat(number: number, numberOfDecimals = 2): number {
    return Number(number.toFixed(numberOfDecimals));
}

import router from '../router/router';
import {translate} from '../shared/mixins/translation';
import {useToast} from '../shared/components/toast/useToast';
import ApiError from './models/ApiError';
import CredentialsError from './helpers/CredentialsError';
import OIDCRepository from './repositories/OIDCRepository.js';
import AuthClient from './client/authClient.js';
import {useLocaleStore} from '../stores/localeStore';
import {useSecurityStore} from '../stores/securityStore';
import {useAuthorizationStore} from '../stores/authorizationStore';
import {useOidcStore} from '../stores/oidcStore';
import {saveRoute} from '../router/guardHelper';
import {useMsTeamsStore} from '../stores/msTeamsStore';
import {postMessage} from './helpers/messageHelper';
import {useUserStore} from '../stores/userStore';
import moment from 'moment';
import {useAccountStore} from '../stores/accountStore';
import {useGlobalStore} from '../stores/globalStore';
import * as Sentry from '@sentry/vue';
import flatMap from 'lodash/flatMap';
import {useApiStore} from '../stores/apiStore';
import SecurityRepository from './repositories/SecurityRepository';
import {HandledApiError} from './models/HandledApiError';
import {initMsalSilentLogin} from './authentication/msal';

/**
 *
 * @type {Map<string, Array<import('moment').Moment>>}
 */
const requestMap = new Map();

const requestThreshold = {
    seconds: 10,
    count: 20,
};

function getAdditionalErrorInformation() {
    try {
        const {user} = useUserStore();
        const {account} = useAccountStore();
        const {authType} = useSecurityStore();

        return {userId: user?.id, accountId: account?.id, authType};
    } catch (error) {
        return {caughtError: error};
    }
}

function clearOldEntries() {
    const deletionThresholdDate = moment().subtract({second: 30});

    for (const key of requestMap.keys()) {
        const urlRequests = requestMap.get(key);
        const currentRequests = urlRequests.filter(timestamp =>
            timestamp.isAfter(deletionThresholdDate)
        );
        if (!currentRequests.length) {
            requestMap.delete(key);
        } else if (currentRequests.length !== urlRequests.length) {
            requestMap.set(key, currentRequests);
        }
    }
}

function checkRequestLoop(url) {
    if (!requestMap.has(url)) {
        requestMap.set(url, []);
    }

    const urlRequests = requestMap.get(url);

    urlRequests.push(moment());

    clearOldEntries();

    const thresholdDate = moment().subtract({second: requestThreshold.seconds});

    const requestsAfterThreshold = urlRequests.filter(timestamp =>
        timestamp.isAfter(thresholdDate)
    );

    if (requestsAfterThreshold.length >= requestThreshold.count) {
        const sentryErrorObject = getAdditionalErrorInformation();

        sentryErrorObject.previousRequestUrls = flatMap(
            Array.from(requestMap).map(([name, timestamps]) =>
                timestamps.map(timestamp => `${timestamp.format('HH:mm:ss:SSS')} | ${name}`)
            )
        ).sort();

        try {
            useGlobalStore().resetWithExceptions();
        } catch {
            sentryErrorObject.resetWithExceptionFailed = true;
        }

        sentryErrorObject.message = 'checkRequestLoop';
        Sentry.captureException(sentryErrorObject);

        localStorage.clear();
        postMessage('desklyTokenExpired');
        window.location.reload();

        return false;
    }

    return true;
}

/**
 * @param {string} url
 * @param {string} method
 * @param {Object} [headers={}]
 * @param {Object|null} [body=null]
 * @param {boolean} [includeAuthHeaders=true]
 * @return {Promise<Response>}
 */
function fetchWithLocale(url, method, headers = {}, body = null, includeAuthHeaders = true) {
    const {currentLocale} = useLocaleStore();
    const {isRateLimited} = useApiStore();

    if (includeAuthHeaders) {
        const {authorizationHeaders} = useAuthorizationStore();
        headers = {...headers, ...authorizationHeaders};
    }

    if (!checkRequestLoop(url) || isRateLimited) {
        return new Promise(() => {});
    }

    return fetch(`/${currentLocale}${url}`, {
        method,
        headers,
        body,
    });
}

export default {
    fetch: fetchWithLocale,
    handle: (response, silent = false) => {
        handleRateLimiting(response);
        handleWebappUpgradeRequired(response);

        if (response.url.match('/w{2}/login$') && response.redirected) {
            useToast().warning('Unexpected error! Please reauthenticate.');
            router.push({name: 'Login'});
        }

        const contentType = response.headers.get('content-type');

        if (!contentType) {
            return handleTextResponse(response, silent);
        }

        if (contentType.includes('application/json')) {
            return handleJSONResponse(response, silent);
        }

        if (contentType.includes('application/problem+json')) {
            return response
                .json()
                .then(json => Promise.reject(ApiError.fromResponse(json, silent)));
        }

        if (contentType.includes('text/html') || contentType.includes('text/csv')) {
            return handleTextResponse(response, silent);
        }

        if (response.status === 404) {
            throw new HandledApiError({
                status: 404,
                statusText: 'not found',
            });
        }

        // Other response types as necessary.
        throw new Error(`Sorry, content-type ${contentType} not supported`);

        function handleJSONResponse(response, silent) {
            return response.json().then(json => {
                if (!response.ok) {
                    throw new HandledApiError({
                        status: response.status,
                        statusText: response.statusText,
                        json: json,
                        silent: silent,
                    });
                }

                if (json === null) {
                    return null;
                }

                if (json.success === false) {
                    throw new HandledApiError({
                        status: json.success,
                        statusText: json.error,
                        silent: silent,
                    });
                }

                if ('undefined' === typeof json.totalCount) {
                    return json;
                }

                if ('undefined' !== typeof json.roomAvailableSeats) {
                    return {
                        data: json.data,
                        allSeats: json.allSeats,
                        roomAvailableSeats: json.roomAvailableSeats,
                        totalCount: json.totalCount,
                    };
                }

                return {
                    data: json.data,
                    totalCount: json.totalCount,
                };
            });
        }

        function handleTextResponse(response, silent) {
            return response.text().then(text => {
                if (response.ok) {
                    return text;
                }

                throw new HandledApiError({
                    status: response.status,
                    statusText: response.statusText,
                    err: text,
                    silent: silent,
                });
            });
        }

        function handleWebappUpgradeRequired(response) {
            const currentWebappVersion = import.meta.env.VITE_CDN_VERSION;
            const actualWebappVersion = response.headers.get('x-webapp-version');

            if (
                !currentWebappVersion ||
                !actualWebappVersion ||
                +currentWebappVersion === +actualWebappVersion
            ) {
                return;
            }

            const apiStore = useApiStore();

            if (apiStore.isToastMuted()) {
                return;
            }

            const updateRequiredMessageKey = 'app.notification.webapp_version.text';
            const upgradeRequiredMessage = translate(updateRequiredMessageKey);
            const updateRequiredLinkLabelKey = 'app.notification.webapp_version.link_text';
            const upgradeRequiredLinkLabel = translate(updateRequiredLinkLabelKey);

            if (
                updateRequiredMessageKey === upgradeRequiredMessage ||
                updateRequiredLinkLabelKey === upgradeRequiredLinkLabel
            ) {
                return;
            }

            apiStore.setTimestamp();

            useToast().info(upgradeRequiredMessage, {
                customData: {
                    link: {
                        label: upgradeRequiredLinkLabel,
                        callbackFn: () => window.location.reload(),
                    },
                },
                life: 0,
            });
        }

        async function handleRateLimiting(response) {
            const apiStore = useApiStore();
            if (apiStore.isRateLimited) {
                return await new Promise(() => {});
            }

            if (response.status === 429) {
                apiStore.startRateLimiting();
                return await new Promise(() => {});
            }
        }
    },
    error: async error => {
        if (error.silent) {
            return error;
        }

        const isApiError = error instanceof ApiError;
        const toast = useToast();
        const securityStore = useSecurityStore();
        const oidcStore = useOidcStore();
        const authorizationStore = useAuthorizationStore();

        if (error instanceof CredentialsError) {
            // special toast handling for unconfirmed users
            if (error.error.type === 'user.registration.not.confirmed.ly') {
                error = error.error;

                toast.error(translate('app.toast.login.registration_not_confirmed.title'), {
                    detail: translate('app.toast.login.registration_not_confirmed.message'),
                    customData: {
                        link: {
                            label: translate(
                                'app.toast.login.registration_not_confirmed.link_label'
                            ),
                            callbackFn: async () =>
                                await SecurityRepository()
                                    .resendUserVerificationEmail(error.data.userId)
                                    .then(() => {
                                        toast.success(
                                            translate('app.toast.login.sso.sso_mail_resend_success')
                                        );
                                    }),
                        },
                    },
                    life: 0,
                });

                throw new HandledApiError({
                    status: error.status,
                    statusText: error.detail,
                    json: {error: error.title, data: error.data},
                });
            }

            const jsonError = error.error.title;
            if (jsonError) {
                toast.error(jsonError);
                throw new HandledApiError({
                    status: error.error.status,
                    statusText: error.error.detail,
                    json: {error: jsonError, data: error.error.data},
                });
            }
            error = error.error;
        }

        switch (error.status) {
            case 401: {
                authorizationStore.incrementUnauthorizedRequestsCount();

                if (authorizationStore.unauthorizedRequestsCount > 5) {
                    authorizationStore.resetUnauthorizedRequestsCount();
                    securityStore.clearToken(true);
                    postMessage('desklyTokenExpired');

                    throw new HandledApiError({
                        status: error.status,
                        statusText: isApiError ? error.title : error.statusText,
                    });
                }

                if (error.type === 'invalid.oidc.configuration.ly') {
                    if (router.currentRoute.value.name !== 'Login') {
                        toast.warning(translate('app.errors.session_expired'));
                    }

                    authorizationStore.resetUnauthorizedRequestsCount();
                    saveRoute(router.currentRoute.value);
                    securityStore.clearToken(true);

                    throw new HandledApiError({
                        status: error.status,
                        statusText: isApiError ? error.title : error.statusText,
                    });
                }

                if (error.type === 'oidc.access.token.expired.ly') {
                    const result = await OIDCRepository().fetchRefreshToken();

                    if (result?.status === 400 && result?.type === 'no.refresh.token.ly') {
                        oidcStore.clearOidc();
                        AuthClient.oidc(false);

                        throw new HandledApiError({
                            status: error.status,
                            statusText: isApiError ? error.title : error.statusText,
                        });
                    }

                    authorizationStore.resetUnauthorizedRequestsCount();
                    oidcStore.accessToken = result.access_token;

                    window.location.reload();

                    throw new HandledApiError({
                        status: error.status,
                        statusText: isApiError ? error.title : error.statusText,
                    });
                }

                const userStore = useUserStore();

                if (userStore.isAuthenticated) {
                    const {isMSTeams} = useMsTeamsStore();

                    if (securityStore.authType === 'MS-Access-Token') {
                        if (isMSTeams) {
                            authorizationStore.resetUnauthorizedRequestsCount();
                            securityStore.clearToken(true);
                            postMessage('desklyTokenExpired');

                            throw new HandledApiError({
                                status: error.status,
                                statusText: isApiError ? error.title : error.statusText,
                            });
                        } else {
                            await initMsalSilentLogin();
                        }
                    }
                }

                return securityStore
                    .refreshAccessToken(true)
                    .then(hasRefreshedToken => {
                        authorizationStore.resetUnauthorizedRequestsCount();
                        // Currently, the page needs to be reloaded to repeat the request
                        if (hasRefreshedToken) {
                            window.location.reload();
                        }
                    })
                    .catch(() => {
                        if (router.currentRoute.value.name !== 'Login') {
                            toast.warning(translate('app.errors.session_expired'));
                        }

                        // Clearing the tokens will redirect the user to the login page
                        authorizationStore.resetUnauthorizedRequestsCount();
                        saveRoute(router.currentRoute.value);
                        securityStore.clearToken(true);
                    })
                    .then(() => {
                        throw new HandledApiError({
                            status: error.status,
                            statusText: isApiError ? error.title : error.statusText,
                        });
                    });
            }
            case 403:
                if (isApiError) {
                    toast.error(error.title);

                    if (error.type === 'little.license.ly' && useUserStore().isAdmin) {
                        toast.info(translate('app.license.notice.free.text'), {
                            customData: {
                                link: {
                                    label: translate('app.license.notice.free.link_text'),
                                    route: {
                                        name: 'AdminLicenseOverview',
                                        params: {account: useAccountStore().account.id},
                                    },
                                },
                            },
                            life: undefined,
                        });
                    }
                } else {
                    toast.error(translate('app.errors.not_authorized'));
                }

                throw new HandledApiError({
                    status: error.status,
                    statusText: isApiError ? error.title : error.statusText,
                    err: error,
                });

            case 404:
                toast.error(translate('app.errors.not_found'));

                throw new HandledApiError({
                    status: error.status,
                    statusText: isApiError ? error.title : error.statusText,
                    cause: error,
                });
            case 409:
                // special case when multiple accounts were found for a domain
                toast.error(translate('app.errors.multiple_domain_collision'));

                throw new HandledApiError({
                    status: error.status,
                    statusText: error.title,
                    json: error.data,
                });
            case 500:
                toast.error(translate('app.errors.internal_error'));

                throw new HandledApiError({
                    status: error.status,
                    statusText: isApiError ? error.title : error.statusText,
                });
            default:
                if (isApiError) {
                    const isSsoVerificationError = [
                        'user.sso.identifier.mismatch.ly',
                        'user.sso.not.verified.ly',
                    ].includes(error.type);

                    if (isSsoVerificationError) {
                        securityStore.blockMsal = true;
                    }

                    let errorMessage = error.title;

                    if (error.data?.formErrors && Object.keys(error.data.formErrors).length) {
                        let bundledErrorMessage = translate('app.errors.invalidDataInput');

                        Object.values(error.data.formErrors).forEach(formError => {
                            bundledErrorMessage += '\n' + formError;
                        });

                        errorMessage = bundledErrorMessage;
                    }

                    if (isSsoVerificationError) {
                        let detail = translate('app.toast.login.sso.not_verified_email_hint');

                        const showResendLink = securityStore.authType === 'MS-Access-Token';

                        if (showResendLink) {
                            detail += ` <br/> <br/>
                            ${translate(
                                'app.toast.login.sso.not_verified_email_resend'
                            )} <br/> <br/>`;
                        }

                        toast.error(errorMessage, {
                            detail: detail,
                            customData: {
                                link: showResendLink
                                    ? {
                                          route: {
                                              name: 'ResendSSOVerification',
                                          },
                                          label: translate(
                                              'app.toast.login.sso.not_verified_resend_link_label'
                                          ),
                                      }
                                    : null,
                            },
                        });
                    } else {
                        toast.error(errorMessage);
                    }

                    throw new HandledApiError({
                        status: error.status,
                        statusText: error.title,
                        json: error.data,
                        type: error.type,
                    });
                } else if (error.json) {
                    if (error.json.error) {
                        toast.error(error.json.error);
                    }

                    const formErrors = error.json.formErrors ?? error.json[0]?.formErrors;

                    if (formErrors && Object.keys(formErrors).length) {
                        let bundledErrorMessage = translate('app.errors.invalidDataInput');

                        Object.values(formErrors).forEach(formError => {
                            bundledErrorMessage += '\n' + formError;
                        });

                        toast.error(bundledErrorMessage);
                    }

                    throw new HandledApiError({
                        status: error.status,
                        statusText: error.statusText,
                        json: error.json,
                    });
                }

                //frontend errors thrown by vue
                throw error;
        }
    },
    /**
     * @param {string} url
     * @return {Promise<Response>}
     */
    get: (url, includeAuthHeaders = true) =>
        fetchWithLocale(url, 'GET', {}, null, includeAuthHeaders),
    /**
     * @param {string} url
     * @param {Object} data
     * @return {Promise<Response>}
     */
    post: (url, data) => fetchWithLocale(url, 'POST', {}, data),
    /**
     * @param {string} url
     * @param {Object} data
     * @param {boolean} [includeAuthHeaders=true]
     * @return {Promise<Response>}
     */
    postJSON: (url, data, includeAuthHeaders = true) =>
        fetchWithLocale(
            url,
            'POST',
            {'Content-Type': 'application/json'},
            JSON.stringify(data),
            includeAuthHeaders
        ),
    /**
     * @param {string} url
     * @return {Promise<Response>}
     */
    delete: url => fetchWithLocale(url, 'DELETE'),
    /**
     * @param {string} url
     * @param {object | null} data
     * @return {Promise<Response>}
     */
    put: (url, data = null) =>
        fetchWithLocale(url, 'PUT', {'Content-Type': 'application/json'}, JSON.stringify(data)),
    /**
     * @param {string} url
     * @param {object} data
     * @return {Promise<Response>}
     */
    patch: (url, data) =>
        fetchWithLocale(url, 'PATCH', {'Content-Type': 'application/json'}, JSON.stringify(data)),
};

<template>
    <prime-vue-progress-bar :value="value">
        <slot v-if="hasTemplate" />
        <span v-else />
    </prime-vue-progress-bar>
</template>
<script setup lang="ts">
import PrimeVueProgressBar from 'primevue/progressbar';
import {computed, type ComputedRef, type Slots, useSlots} from 'vue';

type Props = {
    value: number;
};
defineProps<Props>();

const slots: Slots = useSlots();
const hasTemplate: ComputedRef<boolean> = computed((): boolean => !!slots.default);
</script>

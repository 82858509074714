import {defineStore} from 'pinia';
import {useLocalStorage, StorageSerializers, until} from '@vueuse/core';
import {computed, ref, watch} from 'vue';
import SecurityRepository from '../api/repositories/SecurityRepository';
import {useRouter} from 'vue-router';
import {useInformationStore} from './informationStore';

export const useImpersonatingStore = defineStore('impersonating', () => {
    /**
     * The currently impersonated user object, stored in local storage.
     * @type {import('vue-use/shared').RemovableRef<Object | null>}
     */
    const impersonating = useLocalStorage('impersonating', null, {
        serializer: StorageSerializers.object,
    });

    /**
     * The auth token of the impersonated user.
     * @type {import('vue').ComputedRef<string>}
     */
    const impersonatingToken = computed(() => impersonating.value?.token);

    /**
     * Whether impersonating is currently active.
     * @type {import('vue').ComputedRef<boolean>}
     */
    const isImpersonating = computed(() => !!impersonating.value);

    const loading = ref(false);

    const {refresh} = useInformationStore();

    /**
     * Starts impersonating the user with specified id.
     * @param {string} id Id of the user to be impersonated.
     * @return {Promise<void>} A Promise that resolves when the impersonating data is fully loaded.
     */
    async function impersonate(id) {
        loading.value = true;

        try {
            impersonating.value = await SecurityRepository().impersonate(id);
            await refresh();
        } catch (error) {
            impersonating.value = null;
            throw error;
        } finally {
            loading.value = false;
        }
    }

    /**
     * Cancels the current impersonation.
     * @return {Promise<void>} A promise that resolves when the impersonation was successfully cancelled
     */
    async function reset() {
        if (!isImpersonating.value) {
            return;
        }

        try {
            loading.value = true;
            await SecurityRepository().logout();
            impersonating.value = null;
            await refresh();
        } catch {
            impersonating.value = null;
        } finally {
            loading.value = false;
        }
    }

    useImpersonationChangedRedirect(impersonating, loading);

    return {impersonatingToken, impersonating, isImpersonating, loading, impersonate, reset};
});

/**
 * Sets up redirect to the home page (or a refresh) when `impersonating` changes.
 * @param {import('vue').Ref<Object | null>} impersonating
 * @param {import('vue').Ref<boolean>} loading
 */
function useImpersonationChangedRedirect(impersonating, loading) {
    const router = useRouter();

    watch(impersonating, async (current, previous) => {
        const hasStartedImpersonation = !!current && previous === null;
        const hasCancelledImpersonation = current === null && !!previous;

        if (!hasStartedImpersonation && !hasCancelledImpersonation) {
            return;
        }

        // Wait for the information call of reset() to finish
        // before potentially reloading the page
        if (hasCancelledImpersonation) {
            await until(loading).toBe(false);
        }

        if (router.currentRoute.value.name === 'Home') {
            router.go(0);
        } else {
            void router.push({name: 'Home'});
        }
    });
}

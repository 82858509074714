import type {SelectButtonPassThroughOptions} from 'primevue/selectbutton';

export default {
    root: ({props}) => ({
        class: [
            'inline-flex rounded-full border p-1 border-zinc-300 select-none align-bottom outline-transparent gap-2',
            'rounded-full',

            // Invalid State
            {
                'border-red-500 dark:border-red-400': props.invalid,
                'border-transparent': !props.invalid,
            },
        ],
    }),
} as SelectButtonPassThroughOptions;

import API from '../api';
import extractFormDataFromObject from '../helpers/FormDataHelper';
import repositoryHelper from '../helpers/RepositoryHelper';
import DayBookingView from '../models/view/DayBookingView';
import SeatView from '../models/view/SeatView';

export default () => ({
    browseAdmin(roomId) {
        return API.get(`/api/admin/seats-by-room/${roomId}`)
            .then(API.handle)
            .catch(API.error)
            .then(raw => raw.map(singleRaw => new SeatView(singleRaw)));
    },
    filter(location, room, characteristics, days, timeframe, from, until) {
        const url = repositoryHelper.methods.createFilterUrl(
            '/api/seats/filter',
            location,
            room,
            characteristics,
            days,
            timeframe,
            from,
            until
        );

        return API.get(url)
            .then(API.handle)
            .catch(API.error)
            .then(raw => ({
                filteredSeats: raw.filteredSeats.map(singleRaw => new SeatView(singleRaw)),
                allSeats: raw.allSeats.map(singleRaw => new SeatView(singleRaw)),
                roomAvailableSeats: raw.roomAvailableSeats,
                totalCount: raw.totalCount,
            }));
    },
    filterOccupied(location, room, characteristics, days, timeframe, from, until) {
        const url = repositoryHelper.methods.createFilterUrl(
            '/api/seats/occupied/filter',
            location,
            room,
            characteristics,
            days,
            timeframe,
            from,
            until
        );

        return API.get(url)
            .then(API.handle)
            .catch(API.error)
            .then(raw => raw.occupiedSeats);
    },
    details(seatId) {
        return API.get(`/api/seat/${seatId}`)
            .then(API.handle)
            .catch(error => {
                error.silent = true;
                API.error(error);
            })
            .then(json => new SeatView(json));
    },
    edit(seat, roomId, authorizationGroups) {
        const characteristics = seat['characteristics'];
        delete seat['characteristics'];

        const formData = extractFormDataFromObject({...seat, authorizationGroups}, 'seat');
        characteristics.forEach((characteristic, index) => {
            formData.set(`seat[characteristics][${index}][name]`, characteristic.name);

            if (characteristic.name !== characteristic.id) {
                formData.set(`seat[characteristics][${index}][characteristic]`, characteristic.id);
            }
        });

        return API.post(`/api/admin/room/${roomId}/seat/${seat.id}`, formData)
            .then(API.handle)
            .catch(API.error);
    },
    add(seat, roomId, authorizationGroups) {
        const characteristics = seat['characteristics'];
        delete seat['characteristics'];

        const formData = extractFormDataFromObject({...seat, authorizationGroups}, 'seat');
        characteristics.forEach((characteristic, index) => {
            formData.set(`seat[characteristics][${index}][name]`, characteristic.name);
            if (characteristic.name !== characteristic.id) {
                formData.set(`seat[characteristics][${index}][characteristic]`, characteristic.id);
            }
        });

        return API.post(`/api/admin/room/${roomId}/seat/new`, formData)
            .then(API.handle)
            .catch(API.error);
    },
    delete(seat) {
        return API.delete(`/api/admin/seat/${seat.id}`).then(API.handle).catch(API.error);
    },
    dayAndYear(seatId, year, month) {
        return API.get(`/api/seatDayBookingsBySeat/${seatId}/${year}/${month}`)
            .then(API.handle)
            .catch(API.error)
            .then(raw => raw.map(singleRaw => new DayBookingView(singleRaw)));
    },
    getNextAvailableSeatName(roomId) {
        return API.get(`/api/admin/room/${roomId}/nextAvailableSeatName`)
            .then(API.handle)
            .catch(API.error);
    },
    browse(location, room, characteristics, days, timeframe, from, until) {
        const url = repositoryHelper.methods.createFilterUrl(
            '/api/seats/browse',
            location,
            room,
            characteristics,
            days,
            timeframe,
            from,
            until
        );

        /*
           Temporarily map the characteristics back to an array.
           When the other endpoint isn't in use anymore,
           the SeatView class and components that use it,
           can be changed.
         */
        function mapJsonToSeatView(json) {
            json.characteristics =
                typeof json.characteristics === 'string'
                    ? json.characteristics
                          .trim()
                          .split(',')
                          .map(name => ({name}))
                    : json.characteristics;
            return new SeatView(json);
        }

        return API.get(url)
            .then(API.handle)
            .catch(API.error)
            .then(raw => ({
                roomAvailableSeats: raw.availableFloorRoomSeatsCounts,
                allSeats: raw.availableSeats.concat(raw.occupiedSeats).map(mapJsonToSeatView),
                filteredSeats: raw.availableSeats.map(mapJsonToSeatView),
                occupiedSeats: raw.occupiedSeats.map(mapJsonToSeatView),
            }));
    },
});
